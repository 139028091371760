@import '@/pages/va.module';
.faqs {
  width: 100%;
  background: #ffffff;
  // height: auto;
  padding: 50px 80px 70px;
  .faqsContainer {
    background: #ffffff;
    margin: 0 auto;
    max-width: 1400px;
    //height: 840px;
    height: auto;
    text-align: center;
    padding: 20px auto;
    // margin-bottom: 24px;
    position: relative;

    .title {
      text-align: center;
      font-size: 48px;
      font-family: 'Noto Sans Display Blod';
      font-weight: bold;
      color: #0e1527;
      padding-top: 59px;
      padding-bottom: 40px;
      margin: 0;
    }

    .item {
      width: 100%;
      max-width: 1180px;
      // height: 149px;
      margin: 0 auto;
      background: #FFFFFF;
      box-shadow: 0px 15px 50px 0px rgba(168,155,155,0.15);
      border-radius: 20px;
      padding: 40px 36px 30px 31px;
      margin-bottom: 24px;

      .question {
        .question1 {
          font-size: 24px;
          font-family: 'Noto Sans Display Blod';
          font-weight: bold;
          color: #0e1527;
          line-height: 35px;
          flex: 1;
        }
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: start;
      }

      .answer {
        margin-top: 23px;
        font-size: 16px;
        font-family: Poppins-Regular, Poppins;
        font-weight: 400;
        color: #708096;
        line-height: 25px;
        text-align: left;
      }
      .down{ 
        cursor: pointer;
        line-height: 1;

      }
    }
  }
  .viewMore{
    margin-top: 56px;
    width: 140px;
    height: 40px;
    border-radius: 20px;
    border: 1px solid #FF3E5F;
    color: #FF3E5F;
    text-align: center;
  }
}

@media screen and (max-width: 1200px) {
  .faqs {
    width: 100%;
    .faqsContainer {
      width: auto;
      .title {
        font-family: Pin1;
        margin: 20px auto 40px;
        padding: 0;
      }
      .item {
        padding: 24px;
        .question {
          .question1 {
            // // width: 267px;
            // line-height: 24px;
          }
        }
      }
    }
  }
}
@media screen and (max-width:1024px) {
  .faqs {
    width: 100%;
    padding: 34px 0px 36px;
    .faqsContainer{
      .title{
        font-size: 24px;
        line-height: 35px;
        margin-bottom: 24px;
      }
      .item{
        padding: 20px;
        border-radius: 0;
        margin-bottom: 8px;
        .question{
          .question1{
            font-size: 14px;
            line-height: 21px;
          }
          display: flex;
          justify-content: space-between;
          text-align: start;
          align-items: center;
        }
        .answer{
          font-size: 14px;
          line-height: 21px;
        }
      }
    }
    .viewMore{
      margin-top: 24px;
      font-size: 16px;
    }
  }
}
